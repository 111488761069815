import React, { useState, useEffect } from 'react';
import './App.css';
import { isMobile } from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './app/home';
import Texto from './app/text';
import Firma from './app/firma'
import CuentaRegresivas from './app/cuentaregresiva';
import Ceremonia from './app/ceremonia';
import anillonegro from './img/anillosnegro.gif';
import EventLocationComponent from './app/ubicacion';
import ConfirmacionAsistencia from './app/confirmacionAsistencia';
import Gallery from './app/nosotros';
import foto1 from './img/DEYED.jpg'
import foto2 from './img/playa3.jpg'
import foto3 from './img/EYD.jpeg'

import AudioPlayer from './app/reproductor';
//import musicFile from '../public/assets/cancion.mp3';
import Itinerario from './app/itinerario';




function App() {
  const [datos, setDatos] = useState([]);
  const targetDate = new Date('2024-12-28T13:00:00');
  const gifSrc = "img/anillosnegro.gif";
  const audioUrl = '../assets/cancion.mp3';
  const mensaje = '';
  const event = {

    location: {
      lat: 17.27130510221149,
      lon: -97.68093765300792,
      address: 'Perú 8, San Nicolas, 69800 Tlaxco, Oax.',
    },
  };
  const images = [
    { url: foto1 },
    { url: foto2 },
    { url: foto3 },

    // Agrega más imágenes según sea necesario
  ];

  const [showInvitation, setShowInvitation] = useState(false);
  const [playMusic, setPlayMusic] = useState(false);
  
  const handleChoice = (play) => {
    setShowInvitation(true);
    setPlayMusic(play);
    setShowInvitation(true)
  };
  if (!isMobile) {
    return (

      <div class="container-web notranslate">
        <div className="row">
          <div className="text-center">
            Para una mejor experiencia,<br /> abre la invitación desde un <b>dispositivo móvil</b>
          </div>


        </div>
      </div>
    )
  }


  return (
    <div className="App notranslate">
      <div >
        <Home ></Home>
        <AudioPlayer play={playMusic} />
        <Texto ></Texto>
        <CuentaRegresivas targetDate={targetDate}>

        </CuentaRegresivas>
        <Ceremonia gifSrc={anillonegro}></Ceremonia>
        <EventLocationComponent event={event}></EventLocationComponent>
        <Gallery images={images} ></Gallery>
        <Itinerario ></Itinerario>
        {/*<ConfirmacionAsistencia ></ConfirmacionAsistencia>*/}
        
        <Firma />

      </div>


      {!showInvitation && isMobile && (
        <div >
          <div className='overlay-div'>
            <button onClick={() => handleChoice(true)}>VER CON MÚSICA</button>

            <button onClick={() => handleChoice(false)}>VER SIN MÚSICA</button>
          </div>


        </div>
      )}

    </div>
  );
}

export default App;
